import React from "react"
import Layout from "@components/layout"
import Meta from "@components/meta"
import * as Heading from "@components/heading"
import PairTable from "@components/table"
import { Link } from "gatsby"
import Chevron from "@svgs/chevron.svg";

const Company = () => (
  <Layout>
    <Meta
      title="会社概要"
      desc="木原興業は岡山の富士電機グループ特約店です。富士電機製品を始めとした各種製品を取り扱う電気・電子機器の総合商社です。"
    />{ }

    <Heading.H1 text="会社情報" />
    <div className="px-5 mb-12 lg:max-w-4xl lg:mx-auto">
      <Heading.H3 text="会社概要" />
      <PairTable values={[
        ['商号', '木原興業株式会社'],
        ['本社所在地', '岡山県岡山市北区田町一丁目4番15号'],
        ['連絡先', 'TEL:086-225-2291\nFAX:086-225-2250'],
        ['設立', '大正11年12月 1日（創業 明治35年）'],
        ['資本金', '2,000万円'],
        ['代表取締役社長', '木原 裕之'],
        ['従業員数', '60名'],
        ['年商', '59億円（2023年12月実績）'],
        ['取引銀行	', '中国銀行　本店営業部\n伊予銀行　岡山支店'],
        ['事業内容	', '・電機事業部門\nコンピュータ応用のFA・PAシステムの販売,\n電子・電気機器、計測制御機器の販売,\n自動制御・メカトロニクスシステムの販売\n・不動産部門\n不動産賃貸　有料駐車場\n・情報システム部門\nAI開発等のシステムコンサルティング・PMサポート'],
        ['建設業許可', '岡山県知事許可（特－3）第 465号\n　電気工事業\n岡山県知事許可（般－3）第 465号\n　機械器具設置工事業　電気通信工事業'],
        ['ＩＳＯ認証', '平成23年1月\n　ISO 9001:2008認証取得\n平成27年1月\n　ISO 9001:2015認証更新(取得範囲：本社、電気事業部門)']
      ]} />

      <Heading.H3 text="その他の会社情報"/>
      <div className="px-4 flex flex-col space-y-2 lg:flex-row lg:justify-between lg:space-y-0 lg:pt-3">
        <Link to="/history" className="flex items-center">
          <Chevron className="w-3 h-3 text-primary" />
          <span className="text-primary">当社の沿革</span>
        </Link>
        <Link to="/office" className="flex items-center">
          <Chevron className="w-3 h-3 text-primary" />
          <span className="text-primary">本社ビル施設</span>
        </Link>
        <Link to="/privacy" className="flex items-center">
          <Chevron className="w-3 h-3 text-primary" />
          <span className="text-primary">プライバシーポリシー</span>
        </Link>
        <Link to="/news" className="flex items-center">
          <Chevron className="w-3 h-3 text-primary" />
          <span className="text-primary">お知らせ</span>
        </Link>
      </div>
    </div>
  </Layout>
)

export default Company;